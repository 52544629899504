import ls from 'localstorage-slim';

function setSearchCriteriaHistory(keyword) {
  if (keyword === '') {
    return;
  }
  const searchCriteriaHistories = ls.get('searchCriteriaHistories') || [];
  const val = {
    keyword: keyword.toString(),
    filters: [], // MEMO: filtersの登録はキーワード検索で絞り込みが実装されたら登録する
  };
  const index = $.inArray(
    val.keyword,
    searchCriteriaHistories.map(v => v.keyword),
  );
  if (index !== -1) {
    searchCriteriaHistories.splice(index, 1);
  }
  searchCriteriaHistories.unshift(val);
  if (searchCriteriaHistories.length > 10) {
    searchCriteriaHistories.pop();
  }
  ls.set('searchCriteriaHistories', searchCriteriaHistories);
}

function searchCriteriaRemoveIcon(index) {
  return `
    <i data-index=${index}
      data-click-element-element-id="header_search_histories_delete"
      data-click-element-element-index="${index}">
      <svg
        width="10"
        height="10"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.73901"
          y="13.989"
          width="18.8903"
          height="2"
          rx="1"
          transform="rotate(-45 0.73901 13.989)"
          fill="#9D9D9D"
        />
        <rect
          x="2.03628"
          y="0.622314"
          width="18.981"
          height="2"
          rx="1"
          transform="rotate(45 2.03628 0.622314)"
          fill="#9D9D9D"
        />
      </svg>
    </i>`;
}

function appendSearchCriteriaDocument(searchCriteriaHistories) {
  let html = '';
  html += '<p class="search-criteria-history-title">検索履歴</p>';
  html += '<ul>';
  for (let i = 0; i < searchCriteriaHistories.length; i += 1) {
    html += '<li class="search-criteria-item">';
    html += searchCriteriaRemoveIcon(i);
    html += '<a data-keyword="';
    html += searchCriteriaHistories[i].keyword;
    html += '" class="search-criteria-link" href="/discover/projects/?keyword=';
    html += encodeURIComponent(searchCriteriaHistories[i].keyword);
    html += '" data-click-element-element-id="header_search_histories"';
    html += ` data-click-element-element-index="${i}"`;
    html += '>';
    html += searchCriteriaHistories[i].keyword;
    html += '</a></li>';
  }
  if (searchCriteriaHistories.length === 0) {
    html += '<li>検索履歴はありません</li>';
  }
  html += '</ul>';
  $('div.search-criteria-histories-wrapper').html(html);
}

function appendPopularKeywordDocument(popularKeywords) {
  if ($('.findForm').find('.search-popular-keywords-title').length > 0) {
    return;
  }
  let html = '';
  html += '<p class="search-popular-keywords-title">人気のキーワード</p>';
  html += '<ul class="search-popular-keywords-wrapper">';
  for (let i = 0; i < popularKeywords.length; i += 1) {
    html += '<li class="search-popular-keywords-item">';
    html += '<a data-keyword="';
    html += popularKeywords[i];
    html +=
      '" class="search-popular-keywords-link" href="/discover/projects/?keyword=';
    html += encodeURIComponent(popularKeywords[i]);
    html += '" data-click-element-element-id="header_search_popular_keywords"';
    html += ` data-click-element-element-index="${i}"`;
    html += '">';
    html += popularKeywords[i];
    html += '</a></li>';
  }
  html += '</ul>';
  $('div.search-criteria-histories-wrapper').append(html);
  if ($('.findForm').find('.search-criteria-history-title').length > 0) {
    $('.search-popular-keywords-title').css('margin-top', '40px');
  }
}

function getPopularKeywords() {
  $.ajax({
    type: 'GET',
    url: '/api/popularkeyword/',
    cache: false,
    dataType: 'json',
  }).success(data => {
    if (data) {
      appendPopularKeywordDocument(data);
    }
  });
}

function setPopularKeywords(keyword) {
  $.ajax({
    type: 'POST',
    url: '/api/popularkeyword/',
    cache: false,
    dataType: 'json',
    data: {
      keyword,
      fuel_csrf_token: window.fuel_csrf_token(),
    },
  });
}

function removeSearchCriteriaHistoriesAt(index) {
  const searchCriteriaHistories = ls.get('searchCriteriaHistories') || [];
  searchCriteriaHistories.splice(index, 1);
  if (searchCriteriaHistories.length === 0) {
    $('div.search-criteria-histories-wrapper').css('display', 'none');
    $('.triggerSidebarNav').removeClass('hidden');
    $('.gestSpace').removeClass('hidden');
    $('.questionnaire_banner').removeClass('hidden');
    $('.menuNormal, .menuAfter').css('border', '1px solid #f0f0f0');
    $('div.search-criteria-histories-wrapper').css('display', 'none');
  }
  ls.set('searchCriteriaHistories', searchCriteriaHistories);
  $('div.search-criteria-histories-wrapper').html('');
  appendSearchCriteriaDocument(searchCriteriaHistories);
  getPopularKeywords();
}

$('div.findFormWrap .findForm').on('submit', e => {
  e.preventDefault();
  const target = $(e.target);
  const keyword = target.find('input.triggerKeyword').val();
  setSearchCriteriaHistory(keyword);
  setPopularKeywords(keyword);
  target[0].submit();
});

$('input.triggerKeyword').on('input', function () {
  const value = $(this).val();
  if (value) {
    $('div.search-criteria-histories-wrapper').css('display', 'none');
  }
});

let spSearchCriteriaHistoryClick = false;
$('input.triggerKeyword').on('focus', function () {
  const value = $(this).val();
  if (value !== '') {
    $('.triggerSidebarNav').removeClass('hidden');
    $('.gestSpace').removeClass('hidden');
    $('.questionnaire_banner').removeClass('hidden');
    $('.menuNormal, .menuAfter').css('border', '1px solid #f0f0f0');
    $('div.search-criteria-histories-wrapper').css('display', 'none');
    return;
  }

  const searchCriteriaHistories = ls.get('searchCriteriaHistories') || [];
  appendSearchCriteriaDocument(searchCriteriaHistories);

  getPopularKeywords();

  $('.triggerSidebarNav').addClass('hidden');
  $('.gestSpace').addClass('hidden');
  $('.questionnaire_banner').addClass('hidden');
  $('.menuAfter').removeClass('menuAfter');
  $('.menuNormal').removeClass('menuNormal');
  $('div.search-criteria-histories-wrapper').css('display', 'block');
});

$('input.triggerKeyword').on('blur', e => {
  e.preventDefault();
  if (spSearchCriteriaHistoryClick) {
    spSearchCriteriaHistoryClick = false;
    return;
  }
  if ($('div.search-criteria-histories-wrapper').css('display') === 'none') {
    return;
  }
  $('.triggerSidebarNav').removeClass('hidden');
  $('.gestSpace').removeClass('hidden');
  $('.questionnaire_banner').removeClass('hidden');
  $('.menuNormal, .menuAfter').css('border', '1px solid #f0f0f0');
  $('div.search-criteria-histories-wrapper').css('display', 'none');
});

$('div.search-criteria-histories-wrapper').on('mousedown', 'i', () => {
  spSearchCriteriaHistoryClick = true;
});
$('div.search-criteria-histories-wrapper').on('click', 'i', function () {
  const index = $(this).data('index');
  removeSearchCriteriaHistoriesAt(index);
});

$('div.search-criteria-histories-wrapper').on('mousedown', 'a', () => {
  spSearchCriteriaHistoryClick = true;
});
$('div.search-criteria-histories-wrapper').on('click', 'a', function () {
  const keyword = $(this).data('keyword');
  setSearchCriteriaHistory(keyword);
});

$('div.search-criteria-histories-wrapper').on('mousedown', () => {
  spSearchCriteriaHistoryClick = true;
});

$(() => {
  const searchCriteriaHistories = ls.get('searchCriteriaHistories') || [];
  appendSearchCriteriaDocument(searchCriteriaHistories);
});
